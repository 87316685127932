import React, { useEffect } from 'react';
import { isAfter } from 'date-fns';

import LayoutPanel from 'components/layout/layoutPanel';
import Image from 'components/common/Image';
import { LinkModal } from 'kh-common-components';
import { modalContents } from '../../constants/modal-content';

function RacInfo (props) {

    useEffect (() => {

		const dateStr = process.env.RAC_CREDIT_OFFER_UPDATE.split('-');
        
        const currentDate = new Date();
        const racCreditOfferUpdateStart = new Date(dateStr[0], dateStr[1]-1, dateStr[2], dateStr[3] ? dateStr[3] : '', dateStr[4] ? dateStr[4] : '');

		const isOfferScreeningExpired = isAfter(currentDate, racCreditOfferUpdateStart);

    const racCreditOfferOld = document.querySelector(`section[data-gatsby-id='rac-credit-offer-old']`);
		const racCreditOfferNew = document.querySelector(`section[data-gatsby-id='rac-credit-offer-new']`);

        if (isOfferScreeningExpired && props.lpgAvailable) {
            racCreditOfferOld.classList.add('d-none');
            racCreditOfferNew.classList.remove('d-none');
        }
    }, [])

  

    let racTerms = props.lpgAvailable ? 'rac-lpg-terms' : 'rac-ng-terms';

  return (
    
    <LayoutPanel background={props.panelBackground}
                 padding={props.panelPadding}
                 {...props}>
        <div className="container">
            <div className="row mer-panel">
              <div className="mer-panel__images col-5 col-lg-2 col-sm-3 mb-0 mr-lg-5">
                  <Image className="mer-panel__image"
                         src="/logo/rac.png"
                         alt="RAC logo"
                  />
              </div>
              <div className="content col-10 col-xl-5 col-md-6 col-sm-7 offset-sm-1 offset-lg-0">
                  {props.ngAvailable &&   
                    <React.Fragment>
                      <h3>Beef up your savings with RAC</h3>
                      <p>Enjoy an extra 3% off your natural gas usage charges when you add your membership to your Kleenheat natural gas account*.</p>
                    </React.Fragment>
                  }
                  {props.lpgAvailable &&   
                    <React.Fragment>
                        <section data-gatsby-id="rac-credit-offer-old">
                            <h3>Discounts for RAC members*</h3>
                            <p>Enter your RAC membership number when placing your cylinder order online and your <strong>${process.env.RAC_CREDIT_OFFER_OLD} discount</strong> will be available for your following cylinder order, redeemable once a year.</p>
                        </section>
                        <section data-gatsby-id="rac-credit-offer-new" className="d-none">
                            <h3>Discounts for RAC members*</h3>
                            <p>Enter your RAC membership number when placing your cylinder order online and your <strong>${process.env.RAC_CREDIT_OFFER_NEW} discount</strong> will be available for your following cylinder order, redeemable once a year.</p>
                        </section>
                    </React.Fragment>
                  }
                  {props.momi &&
                    <React.Fragment>
                      <h3>Exclusive savings for RAC members*</h3>
                      <p>We've partnered with RAC, another homegrown WA brand so that we could provide better value to local households, through an exclusive offer for natural gas and LPG cylinder customers.</p>
                    </React.Fragment>
                  }

                  <div>                  
                    <small><LinkModal {...props}
                      modalContents={modalContents}
                      useModal="true"
                      title="RAC terms & conditions"
                      // linkClass=""
                      displayContent="*Terms and conditions apply"
                      modalTitle="RAC terms & conditions"
                      modalContentName={racTerms}
                      okayButtonLabel="Okay"
                    /></small>
                  </div>
              </div>
          </div>
      </div>
  </LayoutPanel>
  )
}

export default RacInfo;